import { Icon } from "@chakra-ui/react";

export function Isotype(props) {
  return (
    <Icon width={125} height={91} viewBox="0 0 125 91" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M62.693 75.734c-1.503 0-3.006.597-4.25 1.876L48.393 87.844V35.266l10.048-10.192c1.203-1.237 2.663-1.834 4.08-1.876M2.104 34.413L30.66 85.67c2.447 4.392 7.428 5.884 11.851 5.074l-15.03-61.15-25.377 4.819z"
        fill="#E95848"
      />
      <path d="M27.482 29.594l15.03 61.193c4.594-.853 8.587-4.137 8.587-9.254V25.117l-23.617 4.477z" fill="#E95848" />
      <path
        d="M25.55 50.702C11.422 50.702 0 39.36 0 25.372 0 11.343 11.422 0 25.55 0 39.634 0 51.1 11.343 51.1 25.372c0 14.03-11.466 25.33-25.55 25.33z"
        fill="#fff"
      />
      <path
        d="M25.55 43.283c-9.963 0-18.078-8.017-18.078-17.953S15.544 7.377 25.55 7.377c9.962 0 18.078 8.017 18.078 17.953S35.512 43.283 25.55 43.283z"
        fill="#E95848"
      />
      <path
        d="M25.55 43.283c-8.589 0-15.803-5.97-17.606-13.987 1.803-8.017 9.017-13.987 17.605-13.987 8.589 0 15.803 5.97 17.606 13.987-1.803 8.017-9.017 13.987-17.606 13.987z"
        fill="#F4D0C0"
      />
      <path
        d="M62.307 75.734c1.503 0 3.005.597 4.25 1.876l10.049 10.192V35.266L66.558 25.032c-1.203-1.237-2.663-1.834-4.08-1.877M122.896 34.413L94.34 85.67c-2.447 4.392-7.428 5.885-11.851 5.074l15.029-61.192 25.378 4.861z"
        fill="#E95848"
      />
      <path d="M97.518 29.594L82.49 90.787c-4.595-.853-8.588-4.137-8.588-9.254V25.117l23.617 4.477z" fill="#E95848" />
      <path
        d="M99.45 50.702c14.085 0 25.55-11.343 25.55-25.372C125 11.3 113.578 0 99.45 0 85.367 0 73.9 11.343 73.9 25.372c0 14.03 11.466 25.33 25.55 25.33z"
        fill="#fff"
      />
      <path
        d="M99.45 43.283c9.963 0 18.079-8.017 18.079-17.953S109.456 7.377 99.451 7.377c-9.963 0-18.078 8.017-18.078 17.953s8.115 17.953 18.078 17.953z"
        fill="#E95848"
      />
      <path
        d="M99.45 43.283c8.588 0 15.802-5.97 17.606-13.987-1.804-8.017-9.018-13.987-17.606-13.987-8.588 0-15.802 5.97-17.605 13.987 1.803 8.017 9.017 13.987 17.605 13.987z"
        fill="#F4D0C0"
      />
      <path
        d="M31.046 42.43a18.137 18.137 0 01-5.54.852c-8.588 0-15.802-5.97-17.605-13.986C9.404 22.643 14.6 17.398 21.255 15.82a17.607 17.607 0 00-2.748 9.51c0 8.017 5.282 14.797 12.539 17.1zM104.99 42.43a18.14 18.14 0 01-5.54.852c-8.588 0-15.802-5.97-17.605-13.986 1.503-6.653 6.698-11.898 13.354-13.476a17.608 17.608 0 00-2.748 9.51c0 8.017 5.239 14.797 12.539 17.1z"
        fill="#813131"
      />
    </Icon>
  );
}
